.hover_link {
  display: flex;
  justify-content: flex-end;
}

.link_animated {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 120px;
  left: 0;
}

.hover_empty {
  z-index: 99;
  position: fixed;
  top: 100;
  right: 0;
  height: 110px;
  width: 40px;
  overflow: hidden;
  transition: width .5s ease-in-out;
}

.link_animated .link_desc {
  color: '#fff';
  display: 'inline-block';
  width: '100px';
  text-align: 'center';
}

.hover_empty:hover {
  width: 120px;
}

.logo {
  width: 160px;
}