.title {
  margin: 20px 0 0px;
  font-size: 18px;
  font-weight: 600;
}

.phone {
  display: inline-block;
  width: 160px;
  margin-right: 10px;
}

.account_list {
  margin-top: 20px;
}

.account_item {
  margin-bottom: 20px;
}

.account_list {
  margin-top: 20px;
}

.role_item {
  margin-bottom: 20px;
}

.check_first {
  width: 160px;
  margin-top: 10px;
}

.check_group {
  margin: 10px 0px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
}