.main {
  width: 368px;
  margin: 0 auto;
  @media screen and (max-width: @screen-sm) {
    width: 95%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}

.lang {
  width: 100%;
  height: 40px;
  line-height: 44px;
  text-align: right;
}

.content {
  flex: 1;
  padding: 32px 0;
}

.top {
  margin-top: 60px;
  text-align: center;
}

.header {
  height: 44px;
  line-height: 44px;
}

.header a {
  text-decoration: none;
}

.logo {
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}

.title {
  position: relative;
  top: 2px;
  font-weight: 600;
  font-size: 33px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.desc {
  margin-top: 12px;
  margin-bottom: 40px;
}
