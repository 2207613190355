@import '~antd/dist/antd.css';

.search_container {
  margin: 40px 0 20px;
}

.table_title {
  font-size: 18px;
  font-weight: 600;
}

.table_container {
  position: relative;
}

.table_right {
  position: absolute;
  right: 26px;
  top: 12px;
}

.table_count {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 2px;
  text-align: right;
}

.title_count_text {
  text-align: right;
}

.container_title {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 10px;
}

.text_area_number {
  position: absolute;
  width: 100px;
  right: 10px;
  bottom: 10px;
  text-align: right;
}

.comment {
  display: inline-block;
  max-width: 120px;
  padding-right: 10px;
  overflow: hidden; /*内容超出后隐藏*/
  text-overflow: ellipsis; /* 超出内容显示为省略号*/
  white-space: nowrap; /*文本不进行换行*/
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
}

.form_right {
  position: absolute;
  right: 6px;
  bottom: -12px;
  color: #bfbfbf;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.normal_number {
  font-size: 26px;
  font-weight: 600;
}

.people_icon {
  width: 36px;
  height: 36px;
  margin-right: 16px;
}

.ant-checkbox-group .ant-checkbox-group-item {
  width: 180px;
  margin-top: 10px;
}

.checkbox_table_group .ant-checkbox-group .ant-checkbox-group-item {
  display: block;
}

.hand {
  cursor: hand;
}

/* upload */

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

/* 修改大屏表格 */
.header-item {
  font-size: 34px;
  height: 60px !important;
  line-height: 60px !important;
}

/* .dv-scroll-board .header {
  height: 60px;
  line-height: 60px;
} */

.row-item {
  font-size: 34px;
  height: 60px !important;
  line-height: 60px !important;
}

.dv-scroll-board .rows .ceil {
  font-size: 30px;
}