.daily_table {
  border: 1px solid #333;
  /* width: 100%; */
  width: 1100px;
  text-align: center;
}

.daily_table td {
  line-height: 30px;
  font-size: 14px;
  border: 1px solid #333;
  width: 68px;
}

.sec_title {
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
}

.daily_table tbody th {
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  border: 1px solid #333;
}

.sub_title {
  background-color: rgba(175, 224, 161, .5);
}

.statistic {
  background-color: rgba(182, 200, 236, .5);
}

.export_link {
  display: inline-block;
  width: 50px;
}