.container {
  text-align: center;
}


.imgEle {
  width: 180px;
  height: 200px;
  background-size: 100% 100%;
  background-image: url('./no_access.png');
  margin: 80px auto 30px;
}