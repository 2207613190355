#components-layout-custom-trigger {
  min-height: 100vh;
}

#components-layout-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-custom-trigger .logo {
  height: 32px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 16px;
  margin-bottom: 30px;
}

#header_image {
  position: absolute;
  right: 20px;
  top: 0px;
}

.header_boy {
  width: 38px;
  height: 38px;
  margin-left: 12px;
}
